<template>
  <div>
    <h3 class="mb-4 text-lg">{{$t('service_provider_title')}}</h3>
    <h5 class="mb-1 text-sm">{{$t('service_provider_subtitle')}}</h5>
    <SelectOptions
      class="mt-2"
      :dataList="dataList"
      @selected="SelectType"
      :title="$t('service_provider_service_type')"
    />
  </div>
</template>

<script>
import SelectOptions from "../forms/SelectOptions.vue";

export default {
  components: {
    SelectOptions,
  },
  data() {
    return {
      dataList: [
        { id: 1, name: "Alternative healer" },
        { id: 2, name: "Groomer" },
        { id: 3, name: "Pet hotel" },
        { id: 4, name: "Dog hotel" },
        { id: 5, name: "Cat hotel" },
        { id: 6, name: "Transportation" },
        { id: 7, name: "Funerary" },
        { id: 8, name: "Dog trainer" },
        { id: 9, name: "Cat trainer" },
        { id: 10, name: "Dog day care" },
        { id: 11, name: "Insurance" },
      ],
    };
  },
  methods: {
    SelectType(val) {
      this.$emit("blurred", val);
    },
  },
};
</script>
