<template>
  <div>
    <h3 class="mb-4 text-lg">
      {{ $t("service_veterinarian_select_clinic_type") }}
    </h3>
    <h5 class="mb-1 text-sm">
      Select the type of clinic from the drop-down menu.
    </h5>
    <SelectOptions
      class="mt-2"
      :dataList="dataList"
      @selected="SelectType"
      :title="$t('service_veterinarian_clinic_type')"
    />
  </div>
</template>

<script>
import SelectOptions from "../forms/SelectOptions.vue";

export default {
  components: {
    SelectOptions,
  },
  data() {
    return {
      dataList: [
        { id: 1, name: "General practice" },
        { id: 2, name: "Mobile Practice" },
        { id: 3, name: "Species-specific clinic (e.g. avian)" },
        { id: 4, name: "Specialist clinic (e.g. cardiologist)" },
        { id: 5, name: "Emergency service" },
        { id: 6, name: "Hospital" },
      ],
    };
  },
  methods: {
    SelectType(val) {
      this.$emit("blurred", val);
    },
  },
};
</script>
