<template>
  <div class="flex flex-col justify-center items-center">
    <SignupHeader :step="$t('global_step_02_02')" :title="headerTittle" back="join" />
    <div class="w-9/12 mt-20 text-left font-proximaMedium">
      <ServiceProvider @blurred="getType" v-if="this.role == 4" />
      <ServiceVeterinarian @blurred="getType" v-if="this.role == 5" />
      <SubmitButton
        class="w-6/12"
        :title="$t('global_next')"
        background="bg-primary"
        @click.native="next"
      />
    </div>
  </div>
</template>

<script>
import SignupHeader from "../primaryregistration/SignupHeader.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import ServiceProvider from "./ServiceProvider.vue";
import ServiceVeterinarian from "./ServiceVeterinarian.vue";

export default {
  components: {
    SignupHeader,
    SubmitButton,
    ServiceProvider,
    ServiceVeterinarian,
  },
  data() {
    return {
      headerTittle: "",
      servicePracticeType: 0,
      role: localStorage.getItem("role"),
    };
  },

  created() {
    if (this.role == 4) {
      this.headerTittle = this.$t('join_us_profile_pet_service');
    } else {
      this.headerTittle = this.$t('service_veterinarian_clinic_type');
    }
  },
  methods: {
    getType(val) {
      this.servicePracticeType = val;
      console.log(this.servicePracticeType);
    },
    next() {
      this.$router.push({
        name: "PrimaryRegistration",
        params: {
          servicePracticeType: this.servicePracticeType,
        },
      });
    },
  },
};
</script>

<style></style>
